import * as React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import TopNavigation from '../../components/Navigation/TopNavigation'
import { showConfirmModal, showTransactionModal } from '../../store/modals/actions'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { TransactionsController } from '../../controllers'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import PageHeader from '../../components/Page/PageHeader'
import Panel from '../../components/Panel/Panel'
import CardEmptyInfo from '../../components/Card/CardEmptyInfo'
import NumberFormatter from '../../utilities/NumberFormatter'
import ProfileDetailItem from '../../components/Profile/ProfileDetailItem'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import PageLoader from '../../components/Page/PageLoader'
import PageContent from '../../components/Page/PageContent'
import { Helmet } from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { Transaction, CurrentUser, LedgerItemType } from '../../types'
import DetailsList from '../../components/Profile/DetailsList'
import PanelAction from '../../components/Panel/PanelAction'
import moment from '../../utilities/Moment'
import { Style } from '../../styles'
import LinkedLedgerItems from '../../components/Transactions/LinkedLedgerItems'
import LinkLedgerItems from '../../components/Transactions/LinkLedgerItems'
import LinkExpenses from '../../components/Transactions/LinkExpenses'
import LinkedExpenses from '../../components/Transactions/LinkedExpenses'
import styled from 'styled-components'
import LinkedPayments from '../../components/Transactions/LinkedPayments'
import LinkPayments from '../../components/Transactions/LinkPayments'

const StickyCell = styled.div`
    position: sticky;
    top: 115px;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        position: relative;
        top: initial;
    }
`


interface IStateToProps {
    currentUser: CurrentUser
}

interface IDispatchToProps {
    showConfirmModal: typeof showConfirmModal
    showTransactionModal: typeof showTransactionModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id: string }> & WithTranslation

interface IState {
    didInitialLoad: boolean
    transaction: Transaction | null
}

class TransactionShow extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            didInitialLoad: false,
            transaction: null,
        }

        this.onBreadCrumbBackPress = this.onBreadCrumbBackPress.bind(this)
        this.onPageHeaderActionClick = this.onPageHeaderActionClick.bind(this)
        this.onEditNotesClick = this.onEditNotesClick.bind(this)
        this.onModalFormSubmitEditTransaction = this.onModalFormSubmitEditTransaction.bind(this)
        this.renderTransactionView = this.renderTransactionView.bind(this)
        this.onLinkLedgerItemsClick = this.onLinkLedgerItemsClick.bind(this)
        this.onUnlinkLedgerItemsClick = this.onUnlinkLedgerItemsClick.bind(this)
        this.onLinkExpensesClick = this.onLinkExpensesClick.bind(this)
        this.onUnlinkExpensesClick = this.onUnlinkExpensesClick.bind(this)
        this.onLinkPaymentsClick = this.onLinkPaymentsClick.bind(this)
        this.onUnlinkPaymentsClick = this.onUnlinkPaymentsClick.bind(this)
    }

    componentWillMount() {
        this.fetchView()
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { match: { params: previousParams } } = prevProps
        const { match: { params: currentParams } } = this.props

        if (previousParams.id !== currentParams.id) {
            this.fetchView()
        }
    }

    async fetchView() {
        const { match: { params: { id } } } = this.props

        try {
            const transaction = await TransactionsController.getTransaction(id)

            this.setState({ transaction: transaction, didInitialLoad: true })
        } catch (ex) {
            console.error(ex)
        }
    }

    onBreadCrumbBackPress(e) {
        e.preventDefault()

        this.props.history.goBack()
    }

    onPageHeaderActionClick(key: string) {
        switch (key) {
        }
    }

    onEditNotesClick() {
        const { transaction } = this.state

        this.props.showTransactionModal({
            activeTab: 'notes',
            transaction: { id: transaction.id },
            onSubmit: this.onModalFormSubmitEditTransaction,
        })
    }

    onModalFormSubmitEditTransaction(transaction: Transaction) {
        this.setState({ transaction: { ...transaction } })
    }

    async onLinkLedgerItemsClick(ledgerItemIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                ledger_item_ids: [...transaction.ledger_item_ids, ...ledgerItemIds]
            })

            this.setState({ transaction: updatedTransaction })
        } catch (ex) {
            console.error(ex)
        }
    }

    async onUnlinkLedgerItemsClick(ledgerItemIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                ledger_item_ids: transaction.ledger_item_ids.filter(id => !ledgerItemIds.includes(id))
            })

            this.setState({ transaction: { ...updatedTransaction } })
        } catch (ex) {
            console.error(ex)
        }
    }

    async onLinkExpensesClick(expenseIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                expense_ids: [...transaction.expense_ids, ...expenseIds]
            })

            this.setState({ transaction: updatedTransaction })
        } catch (ex) {
            console.error(ex)
        }
    }

    async onUnlinkExpensesClick(expenseIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                expense_ids: transaction.expense_ids.filter(id => !expenseIds.includes(id))
            })

            this.setState({ transaction: { ...updatedTransaction } })
        } catch (ex) {
            console.error(ex)
        }
    }

    async onLinkPaymentsClick(paymentIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                payment_ids: [...transaction.payment_ids, ...paymentIds]
            })

            this.setState({ transaction: updatedTransaction })
        } catch (ex) {
            console.error(ex)
        }
    }

    async onUnlinkPaymentsClick(paymentIds: string[]) {
        const { transaction } = this.state

        try {
            const updatedTransaction = await TransactionsController.update({
                ...transaction,
                payment_ids: transaction.payment_ids.filter(id => !paymentIds.includes(id))
            })

            this.setState({ transaction: { ...updatedTransaction } })
        } catch (ex) {
            console.error(ex)
        }
    }


    renderTransactionView() {
        const { t } = this.props
        const { transaction } = this.state

        const { currentUser: { workspace: { setting } } } = this.props
        const isTransactionPositive = Number(transaction?.amount) > 0

        return (
            <div className='grid'>
                <StickyCell className='grid-cell with-4col'>
                    <Panel title={t('Transaction::Details')}>
                        <DetailsList>
                            <ProfileDetailItem
                                label={t('Transaction::Account name')}
                                value={transaction?.account?.name || '-'}
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Account number')}
                                value={transaction?.account?.reference || '-'}
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Counterpart name')}
                                value={transaction.counterpart_name || '-'}
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Counterpart account number')}
                                value={transaction.counterpart_account_number || '-'}
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Amount')}
                                value={
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            color: isTransactionPositive ? Style.color.brandSuccess : Style.color.brandDanger
                                        }}>
                                        {NumberFormatter.formatCurrency(transaction.currency, setting.number_format, transaction.amount)}
                                    </span>
                                }
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Linked amount')}
                                value={
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            color: isTransactionPositive ? Style.color.brandSuccess : Style.color.brandDanger
                                        }}>
                                        {NumberFormatter.formatCurrency(transaction.currency, setting.number_format, transaction.linked_amount)}
                                    </span>
                                }
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Remittance information')}
                                value={transaction.remittance_information}
                            />
                            <ProfileDetailItem
                                label={t('Transaction::Executed on')}
                                value={moment(transaction.execution_time).format(setting.date_format)}
                            />
                        </DetailsList>
                    </Panel>
                </StickyCell>

                <div className='grid-cell with-8col'>
                    <div className='grid'>
                        <div className='grid-cell with-12col'>
                            <Panel
                                title={t('Transaction::Notes')}
                                headerRight={<PanelAction icon='pen' onClick={this.onEditNotesClick} />}
                            >
                                {transaction?.notes.length === 0 && <CardEmptyInfo
                                    icon='sticky-note'
                                    description={t('Transaction::No notes added')}
                                    descriptionActionText={t('Transaction::Add note')}
                                    onDescriptionActionClick={this.onEditNotesClick}
                                />}
                                {transaction?.notes.length > 0 && <div className='text-editor'>
                                    <FroalaEditorView model={transaction.notes} />
                                </div>}
                            </Panel>
                        </div>

                        {Number(transaction?.amount) > 0 && <>
                            {transaction?.ledger_item_ids?.length > 0 && <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Linked invoices')} contentClasses='no-padding'>
                                    <LinkedLedgerItems
                                        type={LedgerItemType.INVOICE}
                                        ledgerItemIds={transaction.ledger_item_ids}
                                        onUnlinkClick={this.onUnlinkLedgerItemsClick}
                                    />
                                </Panel>
                            </div>}

                            <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Link invoices')} contentClasses='no-padding'>
                                    <LinkLedgerItems
                                        type={LedgerItemType.INVOICE}
                                        ledgerItemIds={transaction.ledger_item_ids}
                                        onLinkClick={this.onLinkLedgerItemsClick}
                                    />
                                </Panel>
                            </div>
                        </>}

                        {Number(transaction?.amount) < 0 && <>
                            {transaction?.expense_ids?.length > 0 && <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Linked expenses')} contentClasses='no-padding'>
                                    <LinkedExpenses
                                        expenseIds={transaction.expense_ids}
                                        onUnlinkClick={this.onUnlinkExpensesClick}
                                    />
                                </Panel>
                            </div>}
                            {transaction?.ledger_item_ids?.length > 0 && <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Linked credit notes')} contentClasses='no-padding'>
                                    <LinkedLedgerItems
                                        type={LedgerItemType.CREDIT_NOTE}
                                        ledgerItemIds={transaction.ledger_item_ids}
                                        onUnlinkClick={this.onUnlinkLedgerItemsClick}
                                    />
                                </Panel>
                            </div>}
                            {transaction?.payment_ids?.length > 0 && <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Linked payments')} contentClasses='no-padding'>
                                    <LinkedPayments
                                        paymentIds={transaction.payment_ids}
                                        onUnlinkClick={this.onUnlinkPaymentsClick}
                                    />
                                </Panel>
                            </div>}

                            <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Link expenses')} contentClasses='no-padding'>
                                    <LinkExpenses
                                        expenseIds={transaction.expense_ids}
                                        onLinkClick={this.onLinkExpensesClick}
                                    />
                                </Panel>
                            </div>

                            <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Link credit notes')} contentClasses='no-padding'>
                                    <LinkLedgerItems
                                        type={LedgerItemType.CREDIT_NOTE}
                                        ledgerItemIds={transaction.ledger_item_ids}
                                        onLinkClick={this.onLinkLedgerItemsClick}
                                    />
                                </Panel>
                            </div>

                            <div className='grid-cell with-12col'>
                                <Panel title={t('Transaction::Link payments')} contentClasses='no-padding'>
                                    <LinkPayments
                                        paymentIds={transaction.payment_ids}
                                        onLinkClick={this.onLinkPaymentsClick}
                                    />
                                </Panel>
                            </div>
                        </>}
                    </div>
                </div >
            </div >
        )
    }

    render() {
        const { t, currentUser: { workspace: { setting } } } = this.props
        const { didInitialLoad, transaction } = this.state

        return (
            <>
                <Helmet>
                    <title>{t('Transaction::Transaction')}</title>
                </Helmet>
                <TopNavigation
                    icon='arrow-right-arrow-left'
                    title={t('Transaction::Transaction')}
                />
                <ScrollToTopOnMount />

                <PageContent>
                    {!didInitialLoad && <PageLoader />}

                    {didInitialLoad && <>
                        <PageHeader
                            title={t('Transaction::Transaction')}
                            breadcrumbs={[
                                { content: t('Transaction::Back'), url: 'javascript://', onClick: this.onBreadCrumbBackPress }
                            ]}
                            mainActions={[]}
                            onMainActionClick={this.onPageHeaderActionClick}
                        />

                        <Switch>
                            <Route path={RouteHelper.process(ERoute.PATH_TRANSACTION)} component={this.renderTransactionView} />
                        </Switch>
                    </>}
                </PageContent>
            </>
        )
    }
}

const mapStateToProps = (state: AppState): IStateToProps => {
    const {
        authentication: {
            currentUser,
        },
    } = state

    return {
        currentUser: currentUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        showConfirmModal: (options) => dispatch(showConfirmModal(options)),
        showTransactionModal: (options) => dispatch(showTransactionModal(options)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TransactionShow))