import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from "react-i18next"
import Panel from '../../../components/Panel/Panel'
import { DisplayableError, Settings } from '../../../types'
import SettingsController, { ISettingsForm } from '../../../controllers/SettingsController'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import Option from '../../../components/Settings/Option'
import NotificationUtility from '../../../utilities/Notification'
import Button from '../../../components/Button/Button'
import Subtitle from '../../../components/Settings/Subtitle'
import styled from 'styled-components'
import PushNotification from '../../../PushNotification'
import DeviceController from '../../../controllers/DevicesController'
import { isSupported } from 'firebase/messaging'
import { Style } from '../../../styles'

interface IState {
  settings: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
  notificationsSupported: boolean
}

const SettingButton = styled.button`
  color: white;
  text-decoration: none;
  padding: 12px 35px;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2680C2;
  color: #ffffff;
  border: none;

  &:hover {
    background: #195480;
  }
`

const Notifications = () => {
  const { t } = useTranslation()
  const propsSetting: Settings = useSelector<AppState>(state => (state.authentication.currentUser.workspace.setting as Settings)) as Settings
  const [state, setState] = React.useState<IState>({
    settings: propsSetting,
    errors: [],
    form: null,
    notificationsSupported: false
  })
  const { settings, notificationsSupported } = state
  const notificationPermissionEnabled = notificationsSupported && Notification?.permission === 'granted'

  React.useEffect(() => {
    fetchPushNotificationsSupported()
  }, [])

  const fetchPushNotificationsSupported = async () => {
    try {
      setState({ ...state, notificationsSupported: await isSupported() })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onRequestPushNotificationsClick = async (e) => {
    e.preventDefault()

    try {
      const requestedPermission = await PushNotification.requestPermission()

      if (requestedPermission === 'granted') {
        await syncDevice()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const syncDevice = async (e?) => {
    if (e) e.preventDefault()

    try {
      const token = await PushNotification.getToken()

      if (token) {
        await DeviceController.syncDevice({
          token: token
        })

        setState({ ...state, errors: [] })

        NotificationUtility.notifySuccess(t('ProfileNotifications::Device successfully synced'));
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await SettingsController.update(settings)

      if (response.errors) {
        NotificationUtility.notifyError(t('ProfileNotifications::Oops something went wrong'));
        setState({ ...state, errors: response.errors })
      } else {
        // Cast to settings
        const settings = response as Settings
        // Update local
        setState({ ...state, settings: settings, errors: [] })

        NotificationUtility.notifySuccess(t('ProfileNotifications::Settings successfully updated'));
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onWeeklySummaryChange = (subscribed) => {
    setState({
      ...state,
      settings: {
        ...settings,
        weekly_summary_subscription: subscribed
      }
    })
  }

  const onInvoiceViewedChange = (subscribed) => {
    setState({
      ...state,
      settings: {
        ...settings,
        invoice_viewed_subscription: subscribed
      }
    })
  }

  const onInvoicesDueTodayChange = (subscribed) => {
    setState({
      ...state,
      settings: {
        ...settings,
        invoices_due_today_subscription: subscribed
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('ProfileNotifications::{{__appName}} | Notifications')}</title>
      </Helmet>
      <form onSubmit={onFormSubmit}>
        <Subtitle>{t('ProfileNotifications::Email notifications')}</Subtitle>
        <Panel>
          <div className='setting-options'>
            <Option
              title={t('ProfileNotifications::Weekly summary')}
              description={t('ProfileNotifications::Receive a weekly summary email with the financial health records of your company.')}
              onChange={onWeeklySummaryChange}
              checked={settings.weekly_summary_subscription}
            />

            <Option
              title={t('ProfileNotifications::Invoice opened')}
              description={t('ProfileNotifications::Receive an email when your contact opens his invoice.')}
              onChange={onInvoiceViewedChange}
              checked={settings.invoice_viewed_subscription}
            />

            <Option
              title={t('ProfileNotifications::Invoices to be paid')}
              description={t('ProfileNotifications::Receive an email on the day an invoice should be paid by the contact.')}
              onChange={onInvoicesDueTodayChange}
              checked={settings.invoices_due_today_subscription}
            />
          </div>
          <div className='field-actions'>
            <div className='popover-wrapper'>
              <Button type='success' text='Opslaan' onClick={onFormSubmit} />
            </div>
            <div />
            <input type='submit' style={{ display: 'none' }} />
          </div>
        </Panel>

        <Subtitle>{t('ProfileNotifications::Push notifications')}</Subtitle>
        <Panel>
          {!notificationsSupported && <p>
            {t('ProfileNotifications::Push notifications are not supported on your device.')}
          </p>}

          {notificationsSupported && <>
            {!notificationPermissionEnabled && <SettingButton onClick={onRequestPushNotificationsClick}>
              {t('ProfileNotifications::Enable push notifications')}
            </SettingButton>}

            {notificationPermissionEnabled && <p>
              <div>
                {t('ProfileNotifications::You have enabled push notifications. If you want to disable them, you can do so in your device settings.')}
              </div>

              <SettingButton onClick={syncDevice} style={{ marginTop: Style.spacing.x1 }}>
                {t('ProfileNotifications::Sync device')}
              </SettingButton>
            </p>}
          </>}
        </Panel>
      </form >
    </>
  )

}

export default Notifications